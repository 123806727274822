import React from 'react';
// import { Link } from 'gatsby'
import Img from 'gatsby-image';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ButtonOutline from '../button-outline';

const OfferTabs = (props) => {

    const offerTabs = [
        {
            id: 1,
            name: "Bukiety",
        },
        {
            id: 2,
            name: "Flower Boxy",
        },
        {
            id: 3,
            name: "Florystyka ślubna",
        },
        {
            id: 4,
            name: "Florystyka żałobna",
        },
        {
            id: 5,
            name: "Wypożyczalnia",
        },
    ];

    return (
        <Tabs className="space-y-4">
            <div className="relative px-4 sm:px-0">
                <TabList className="relative grid mx-auto overflow-hidden bg-gray-50 divide-y rounded-lg sm:divide-y-0 sm:divide-x sm:max-w-screen-xl text-gray-800 sm:grid-cols-5">
                    {offerTabs.map((item, id) => (
                        <Tab className={`inline-block py-4 lg:py-0 text-center`} key={id}>
                            <span className="cursor-pointer select-none font-light text-sm uppercase px-3 py-1 tracking-wider transition-colors duration-200 hover:text-peru-400">{item.name}</span>
                        </Tab>
                    ))}
                </TabList>
                
            </div>
            <div className="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
                <TabPanel className="flex flex-col items-center space-y-8">

                    <div className="flex flex-wrap justify-center w-full -mx-2 lg:-mx-4">
                        {props.bouquetImages.slice(0, 8).map(({ node: bouquetImagesItem}, index) => (
                            <div className="my-2 px-2 w-1/2 md:w-1/3 lg:my-4 lg:px-4 lg:w-1/4 xl:w-1/5" key={index}>
                                <Img
                                    className='max-w-full h-48 md:h-64 lg:h-72 overflow-hidden rounded-lg shadow-lg'
                                    fluid={bouquetImagesItem.image.fluid}
                                    alt={bouquetImagesItem.image.alt}>
                                </Img>
                            </div>
                        ))}
                    </div>

                    <ButtonOutline link={`oferta/bukiety`}>Więcej</ButtonOutline>
                </TabPanel>
                <TabPanel className="flex flex-col items-center space-y-8">
                    <div className="flex flex-wrap justify-center w-full -mx-2 lg:-mx-4">
                        {props.flowerboxImages.slice(0, 8).map(({ node: flowerboxImagesItem }, index) => (
                            <div className="my-2 px-2 w-1/2 md:w-1/3 lg:my-4 lg:px-4 lg:w-1/4 xl:w-1/5" key={index}>
                                <Img
                                    className='max-w-full h-48 md:h-64 lg:h-72 overflow-hidden rounded-lg shadow-lg'
                                    fluid={flowerboxImagesItem.image.fluid}
                                    title={flowerboxImagesItem.image.filename}
                                    alt={flowerboxImagesItem.image.alt}>
                                </Img>
                            </div>
                        ))}
                    </div>

                    <ButtonOutline link={`oferta/flower-boxy`}>Więcej</ButtonOutline>
                </TabPanel>
                <TabPanel className="flex flex-col items-center space-y-8">
                    <div className="flex flex-wrap justify-center w-full -mx-2 lg:-mx-4">
                        {props.weddingImages.slice(0, 8).map(({ node: weddingImagesItem }, index) => (
                            <div className="my-2 px-2 w-1/2 md:w-1/3 lg:my-4 lg:px-4 lg:w-1/4 xl:w-1/5" key={index}>
                                <Img
                                    className='max-w-full h-48 md:h-64 lg:h-72 overflow-hidden rounded-lg shadow-lg'
                                    fluid={weddingImagesItem.image.fluid}
                                    title={weddingImagesItem.image.filename}
                                    alt={weddingImagesItem.image.alt}>
                                </Img>
                            </div>
                        ))}
                    </div>

                    <ButtonOutline link={`oferta/florystyka-slubna`}>Więcej</ButtonOutline>
                </TabPanel>
                <TabPanel className="flex flex-col items-center space-y-8">
                    <div className="flex flex-wrap justify-center w-full -mx-2 lg:-mx-4">
                        {props.funeralImages.slice(0, 8).map(({ node: funeralImagesItem }, index) => (
                            <div className="my-2 px-2 w-1/2 md:w-1/3 lg:my-4 lg:px-4 lg:w-1/4 xl:w-1/5" key={index}>
                                <Img
                                    className='max-w-full h-48 md:h-64 lg:h-72 overflow-hidden rounded-lg shadow-lg'
                                    fluid={funeralImagesItem.image.fluid}
                                    title={funeralImagesItem.image.filename}
                                    alt={funeralImagesItem.image.alt}>
                                </Img>
                            </div>
                        ))}
                    </div>

                    <ButtonOutline link={`oferta/florystyka-zalobna`}>Więcej</ButtonOutline>
                </TabPanel>
                <TabPanel className="flex flex-col items-center space-y-8">
                    <div className="flex flex-wrap justify-center w-full -mx-2 lg:-mx-4">
                        {props.rentalImages.slice(0, 8).map(({ node: rentalImagesItem }, index) => (
                            <div className="my-2 px-2 w-1/2 md:w-1/3 lg:my-4 lg:px-4 lg:w-1/4 xl:w-1/5" key={index}>
                                <Img
                                    className='max-w-full h-48 md:h-64 lg:h-72 overflow-hidden rounded-lg shadow-lg'
                                    fluid={rentalImagesItem.image.fluid}
                                    title={rentalImagesItem.image.filename}
                                    alt={rentalImagesItem.image.filename}>
                                </Img>
                            </div>
                        ))}
                    </div>
                    <ButtonOutline link={`oferta/wypozyczalnia`}>Więcej</ButtonOutline>
                </TabPanel>
            </div>
        </Tabs>
    )
}

export default OfferTabs
