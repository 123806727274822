import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Container from '../components/container';
import Paragraph from "../components/paragraph";
import OfferTabs from '../components/sections/offerTabs';
import FlowerA from '../components/graphics/flowerA';
import FlowerB from '../components/graphics/flowerB';
import Header2 from '../components/header2';

const IndexPage = ({ data }) => {

  const testimonials = [
    {
      id: 1,
      title: "Dekoracja Bazyliki",
      author: "Przemysław Soboń",
      text: "Pani Ula zajmuje się od trzech lat w Bazylice Bożego Ciała w Krakowie dekoracją kwiatową. Jej ogromne  zaangażowanie, poczucie estetyki i smaku, powodują ze wykonywane prace dodają kolorytu i piękna temu miejscu.",
    },
    {
      id: 2,
      title: "Przyozdobienie kościoła",
      author: "Klaudia Helak",
      text: "Dekoracja Kościoła na ślub została przygotowana z sercem i pasją do kwiatów Pełen profesjonalizm, bezproblemowy kontakt - bardzo polecam Było przepięknie:)",
    },
    {
      id: 3,
      title: "Szeroka oferta",
      author: "Estera Niegodzińska",
      text: "Kwiaty i Więcej  to piękne miejsce! Kupiłam tam ostatnio roślinę do domu, roślina piękna, cena super i Pani prowadząca to miejsce bardzo miła i z dużą wiedzą",
    },
    {
      id: 4,
      title: "Świetny wybór",
      author: "Ewelina Curylo",
      text: "Piękne i klimatyczne miejsce z dużym wyborem roślin i do domu i na balkon czy do ogródka Przemiła Pani sprzedawczyni",
    },
    {
      id: 5,
      title: "Fachowa pomoc",
      author: "Michał Tatarczuk",
      text: "Dziękuje bardzo Pani Uli za pomoc w wyborze kwiatów ! Palmy są przepiękne i pięknie się prezentują ! Polecam",
    },
    {
      id: 6,
      title: "Dziękuję!",
      author: "Magdalena Cyganik",
      text: "Pani Ula przepięknie udekorowała Bazylikę Bożego Ciała na uroczystość I Komunii Świętej mojej córki. Podczas Jej pracy widać było, że robi to z wielką pasją, zamiłowaniem i uśmiechem na twarzy Polecam!",
    },
  ];

  const Button = ({ children, link }) => {
    return (
      <a href={link}
        className="py-2 px-6 flex max-w-max justify-center items-center bg-peru-600 hover:bg-peru-500 focus:ring-peru-200 focus:ring-offset-peru-100 text-white transition ease-in duration-200 text-center text-sm font-light uppercase tracking-wider shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
        {children}
      </a>
    )
  };

  const Testimonial = ({ id, author, text }) => {
    return (
      <div className="p-4 md:w-1/2 w-full" key={id}>
        <div className="h-full p-4 rounded-lg space-y-2 border border-peru-200">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="block w-8 h-8 text-peru-200" viewBox="0 0 975.036 975.036">
            <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
          </svg>
          <div className="text-gray-800 space-y-4">
            <Paragraph>{text}</Paragraph>
            <div className="font-medium text-sm">{author}</div>
          </div>
        </div>
      </div>
    )
  };

  return (
    <Layout>
      <HelmetDatoCms seo={data.datoCmsHome.seoMetaTags} />
      <header>
        <Container>
          <div className="grid gap-10 lg:grid-cols-2">
            <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
              <div className="max-w-xl text-gray-800 space-y-4">
                <h1 className="max-w-lg text-4xl md:text-5xl lg:text-6xl font-bold tracking-tight sm:leading-none">
                  Pomożemy Ci dostrzec piękno
                </h1>
                <Paragraph>
                  Bogata oferta kwiatów na każdą okazję. Kompozycje, wieńce, bukiety ślubne i nie tylko...
                </Paragraph>
                <Button link="#oferta">Dowiedz się więcej</Button>
              </div>
            </div>
            <div className="flex items-center justify-center -mx-4 lg:pl-8">
              <div className="flex flex-col items-end lg:px-3">
                <Img className="object-cover rounded-lg mb-3 lg:mb-6 shadow-lg h-36 sm:h-48 xl:h-56 w-36 sm:w-48 xl:w-56" fluid={data.allDatoCmsHeroImage.edges[0].node.images[2].fluid} alt="Kwiaty i Więcej" />
                <Img className="object-cover w-28 h-28 rounded-lg shadow-lg sm:h-32 xl:h-40 sm:w-32 xl:w-40" fluid={data.allDatoCmsHeroImage.edges[0].node.images[0].fluid} alt="Kwiaty i Więcej" />
              </div>
              <div className="px-3">
                <Img className="object-cover rounded-lg shadow-lg w-40 h-40 sm:h-64 xl:h-80 sm:w-64 xl:w-80" fluid={data.allDatoCmsHeroImage.edges[0].node.images[1].fluid} alt="Kwiaty i Więcej" />
              </div>
            </div>
          </div>
        </Container>
      </header>

      <div id="oferta" className="container px-5 py-4 lg:py-24 mx-auto flex flex-wrap flex-col">
        <OfferTabs
          bouquetImages={data.allDatoCmsBouquet.edges}
          flowerboxImages={data.allDatoCmsFlowerbox.edges}
          weddingImages={data.allDatoCmsWedding.edges}
          funeralImages={data.allDatoCmsFuneral.edges}
          rentalImages={data.allDatoCmsRental.edges}></OfferTabs>
      </div >

      <Container>
        <div id="o-nas" className="flex flex-col lg:flex-row text-gray-800 relative">
          <FlowerB className="hidden lg:inline absolute opacity-5 ml-96 mt-16"></FlowerB>
          <div className="lg:w-1/2 lg:pr-5">
            <Header2>
              Pracownia florystyczna
              <br className="hidden md:block" />
              Kilka słów o naszej historii
            </Header2>
          </div>
          <div className="lg:w-1/2 space-y-4">
            <Paragraph>
              Początek nie zawsze  jest łatwy, ale może być niezwykle piękny... Młody, niezwykle zdolny, muzyk, artysta, Zbigniew Wodecki, kiedy rozpoczynał swoją karierę muzyczną stwierdził „Żeby odnieść sukces trzeba się związać z porządnym z nazwiskiem” Tak właśnie powstała piosenka "Zacznij od Bacha" którą znają całe pokolenia i która określa koloryt nieżyjącego już artysty. Idąc tym śladem, na początku naszej drogi, stanęła Bazylika Bożego Ciała na krakowskim Kazimierzu, która dla Krakowa jest tym czym Bach dla muzyki.
            </Paragraph>
            <Paragraph>
              Niezwykłość i tajemniczość tego miejsca spowodowała ze mogliśmy wznieść się na najwyższy poziom estetyki i piękna poprzez nasze kompozycje florystyczne które mamy możliwość  nieustannie tworzyć do tego kościoła. Florystyka ślubna, dekoracje Bożonarodzeniowe i Wielkanocne, kompozycje okolicznościowe  które wywoływały zachwyt i radość spowodowały ze powstało miejsce które nazwaliśmy  „ Kwiaty i więcej...”.
            </Paragraph>
            <Paragraph>
              To tu poczujesz niezwykły zapach kwiatów... To tu zobacz kwiaty z których floryści tworzą prawdziwe dzieła sztuki... I wreszcie to tu poczujesz pasje i miłość do kwiatów... jeżeli tego szukasz to jest to miejsce dla Ciebie...
            </Paragraph>
          </div>
        </div>
      </Container>

      <div className="relative">
        <img className="object-cover w-full h-56 sm:h-96" src={data.allDatoCmsAbout.edges[0].node.images[3].url}
          alt="Kwiaciarnia" />
        <div className="absolute inset-0 bg-gray-900 bg-opacity-50">
          <div className="max-w-screen-md h-full flex flex-col justify-center sm:text-center sm:mx-auto space-y-4 px-4">
            <Header2 className={"text-gray-50"}>
              "Szczęście posiadane to ziarno
              <br className="hidden md:block" />
              – szczęście współdzielone to kwiat."
            </Header2>
            <Paragraph className={"italic text-gray-50"}>– John Harrigan</Paragraph>
          </div>
        </div>
      </div>
      <Container>
        <div id="opinie" className="container px-5 py-4 lg:py-24 mx-auto">
          <div className="flex flex-wrap -m-4">
            {testimonials.map((item, id) => (
              <Testimonial key={id} text={item.text} author={item.author} />
            ))}
          </div>
        </div>
      </Container>
      <Container>
        <div className="bg-gray-50 dark:bg-gray-800 relative flex flex-col lg:flex-row items-center space-y-8 lg:gap-8 xl:gap-16">
          <FlowerA className="hidden lg:inline absolute -mt-96 -ml-24 opacity-10"></FlowerA>
          <div className="w-full lg:w-7/12 xl:w-5/12 z-20 text-gray-800 space-y-4">
            <Header2>
              O kwiatach można pisać i mówić bez końca...
            </Header2>
            <Paragraph>
              A i tak będą nas nieustannie zachwycać i zaskakiwać... jedno jest pewne wprowadzają nas w świat piękna i estetyki... Kwiat to roślina, która jest delikatnym odzwierciedleniem człowieka. Jest piękny i delikatny, może odzwierciedlać kobietę.
              Ich piękno odzwierciedla naturalny dziewczęcy urok. Kwiaty dostarczają nam radość, rozweselają każdy dom.
            </Paragraph>
            <Paragraph>
              Kwiat jest rośliną, dzięki której możemy sprawić komuś ogromną przyjemność. Miło jest dostawać kwiaty. Wywołują bardzo pozytywne uczucia w ludziach, którzy je widzą. Podziwiamy osoby, które dla własnej przyjemności zajmują się hodowlą kwiatów. Uwielbiamy  je dostawać, nic nie sprawia nam tyle przyjemności jak prezent w postaci bukietu kwiatów. Umila czas, gdy się na niego patrzymy. Jakże szeroko zakrojony jest wachlarz oddziaływania kwiatów, także my chcemy wypisać się w to niezwykle pole działania które budzi tak wiele pozytywnych uczuć i reakcji.
            </Paragraph>
            <Paragraph>
              W naszej kwiaciarni zrobimy dla Ciebie wszystko, ograniczyć nas może tylko Twoja wyobraźnia...
            </Paragraph>
          </div>
          <div className="w-full lg:w-5/12 xl:w-7/12 flex items-center gap-4 lg:gap-8">
            <Img
              className="object-cover rounded-lg shadow-lg w-1/2 h-48 md:h-72 lg:h-60 xl:h-96"
              fluid={data.allDatoCmsAbout.edges[0].node.images[0].fluid}
              alt="Kompozycja kwiatowa" />
            <div className="w-1/2">
              <Img
                className="object-cover rounded-lg shadow-lg w-full h-48 md:h-72 lg:h-60 xl:h-96 mb-4 lg:mb-8"
                fluid={data.allDatoCmsAbout.edges[0].node.images[1].fluid}
                alt="Kompozycja kwiatowa" />
              <Img
                className="object-cover rounded-lg shadow-lg w-full h-48 md:h-72 lg:h-60 xl:h-96"
                fluid={data.allDatoCmsAbout.edges[0].node.images[2].fluid}
                alt="Kompozycja kwiatowa" />
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query IndexPageQuery {
      datoCmsHome {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
      }
      allDatoCmsHeroImage {
        edges {
          node {
            images {
              filename
              alt
              title
              fluid(imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
              }
            }
          }
        }
      }
      allDatoCmsBouquet {
        edges {
          node {
            image {
              fluid(imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
              }
            }
          }
        }
      }
      allDatoCmsFlowerbox {
        edges {
          node {
            image {
              filename
              fluid(imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
              }
            }
          }
        }
      }
      allDatoCmsWedding {
        edges {
          node {
            image {
              filename
              fluid(imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
              }
            }
          }
        }
      }
      allDatoCmsFuneral {
        edges {
          node {
            image {
              filename
              fluid(imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
              }
            }
          }
        }
      }
      allDatoCmsRental {
        edges {
          node {
            image {
              filename
              fluid(imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
              }
            }
          }
        }
      }
      allDatoCmsAbout {
        edges {
          node {
            images {
              url
              fluid(imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
              }
            }
          }
        }
      }
    }
`
